<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-tab
        :dense="true"
        type="pagetop"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        setHeight="500px"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            dense
            :is="tab.component"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
export default {
  name: 'firefighting-equipment-tab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      tab: 'firefightingEquipmentInfo',
      tabItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.tabItems = [
        // 기본정보 정보
        { name: 'firefightingEquipmentInfo', icon: 'info', label: 'LBL0010497', component: () => import(`${'./firefightingEquipmentInfo.vue'}`) },  // 소화설비 설치계획
        // 도면
        { name: 'firefightingEquipmentPid', icon: 'tab', label: 'LBL0010498', component: () => import(`${'./firefightingEquipmentPid.vue'}`) }, // 소화설비 설치도
        // 문서
        { name: 'firefightingEquipmentDoc', icon: 'assignment', label: 'LBL0010499', component: () => import(`${'./firefightingEquipmentDoc.vue'}`) } // 소화설비 설치 계획 문서
      ]
    },

  }
};
</script>